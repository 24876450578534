'use strict'

angular
.module 'mundoAdmin.notifications'
# .config ($stateProvider) ->
#   $stateProvider
#   .state 'notifications',
#     url: '/notifications'
#     parent: 'admin'
#     views:
#       '@admin':
#         templateUrl: 'mundo-admin/notifications/views/notifications.tpl.html'
#         controller: 'TabsCtrl'
#         controllerAs: 'tabsCtrl'
#     deepStateRedirect: {default: {state: 'notifications.notifications'}},

#   .state 'notifications.notifications',
#     url: '/notifications'
#     data:
#       'selectedTab': 0
#     views:
#       'notifications@notifications':
#         templateUrl: 'mundo-components/mundo-data-table/views/mundo-data-table.tpl.html'
#         controller: 'DataTableCtrl'
#         controllerAs: 'listCtrl'
#         resolve:
#           entityManager: (NotificationsManager) ->
#             NotificationsManager
#           pageTitle: ->
#             'app.admin.pageTitles.notifications.notifications'
#           pageDescription: ->
#             'app.admin.descriptions.notifications.notifications'
#           deleteBoxTitle: ->
#             'app.admin.actions.notifications.deleteNotification'
#           deleteBoxBody: ->
#             'app.admin.actions.notifications.deleteNotification'
#           AddBoxTitle: ->
#             'app.admin.actions.notifications.addNotification'
#           editBoxTitle: ->
#             'app.admin.actions.notifications.editNotification'
#           loadFilterParams: ->
#             {}

          # loadFilterParams: ->
          #   {
          #     'filter[closedAt]': 'OR,closedAt,NULL,closedAt,GTE ' + moment.utc().format()
          #   }
